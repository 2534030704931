import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import { useIntl } from 'gatsby-plugin-intl'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Collapse } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandMore'
import ExpandMore from '@material-ui/icons/ChevronRight'
import myCountries from '../../../../data/countries.json'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
    textTransform: 'uppercase',
    fontFamily: 'Gotham Medium',
    fontSize: '14px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  listItem: {
    padding: theme.spacing(2),
  },
  countryTitle: {
    fontFamily: `'Gotham Medium', serif`,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
  },
  cityTitle: {
    fontFamily: `'Gotham Light',  serif`,
    fontWeight: 325,
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
  },
  selectedCity: {
    fontFamily: `'Gotham Medium',  serif`,
  },
  subtitle: {
    fontFamily: `'Gotham Medium',  serif`,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
    paddingTop: '1.5rem',
  },
}))
interface NodeAgent {
  id: string
  lname: string
  photo: any
  destination: string
  email: string
  phone: string
  descriptionAr: string
  descriptionEn: string
  descriptionEs: string
  descriptionFr: string
  descriptionPt: string
  descriptionRu: string
  descriptionZh: string
  titreAr: string
  titreEn: string
  titreEs: string
  titreFr: string
  titrePt: string
  titreRu: string
  titreZh: string
}

interface Agent {
  node: NodeAgent
}
interface City {
  city: string
  agents: Agent[]
}
interface Country {
  country: string
  cities: City[]
  regions: Region[]
}
interface Location {
  name: string
  agents: Agent[]
}
interface Region {
  region: string
  agents: Agent[]
}

interface MobileSidebarProps {
  countries: Country[] // Liste des pays avec leurs villes
  onCitySelect?: (location: Location) => void // Envoie la liste des agents
  isOpen: boolean // Contrôle si le menu est ouvert ou fermé
  toggleSidebar: () => void // Fonction pour ouvrir/fermer le menu
  selectedCity: string
}

const MobileSidebar: React.FC<MobileSidebarProps> = ({
  countries,
  onCitySelect,
  isOpen,
  toggleSidebar,
  selectedCity,
}) => {
  const intl = useIntl()
  const classes = useStyles()
  const [openCountries, setOpenCountries] = useState<{ [key: string]: boolean }>({})

  const handleCountryToggle = (country: string) => {
    setOpenCountries((prev) => ({
      ...prev,
      [country]: !prev[country],
    }))
  }

  const handleCityClick = (location: Location) => {
    onCitySelect?.(location)
    toggleSidebar() // Fermer le menu après sélection
  }

  useEffect(() => {
    if (
      countries.length > 0 &&
      countries[0].cities.length > 0 &&
      Array.isArray(countries[0].cities[0].agents) // Vérifie bien que c'est un tableau
    ) {
      onCitySelect({ name: countries[0]?.cities[0]?.city, agents: countries[0]?.cities[0]?.agents })
    }
  }, [])

  function getCountryfromLabel(code: string) {
    return myCountries.find((country) => country.country_code === code)
  }

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={toggleSidebar}
      classes={{ paper: classes.drawerPaper }}
    >
      {/* En-tête avec bouton Close et titre */}
      <Box className={classes.header}>
        <Typography variant="h6" component="h2" className={classes.title}>
          {intl.formatMessage({ id: 'new.Equipe_2' })}
        </Typography>
        <IconButton onClick={toggleSidebar}>
          <CloseIcon style={{ fill: '#000' }} />
        </IconButton>
      </Box>

      {/* Liste des pays et villes */}
      <List className={classes.list}>
        {countries.map((country) => (
          <div key={country.country}>
            {/* Affichage du pays avec icône d'expansion */}
            <ListItem button>
              <ListItemText
                onClick={() =>
                  handleCityClick({
                    name:
                      country.cities.find((city) => city.city === 'all')?.city ||
                      country.cities[0].city,
                    agents:
                      country.cities.find((city) => city.city === 'all')?.agents ||
                      country.cities[0].agents,
                  })
                }
                classes={{ primary: classes.countryTitle }}
                primary={intl.formatMessage({
                  id: `destinations.${getCountryfromLabel(country?.country)?.label.toLowerCase()}`,
                })}
              />
              {['FR', 'ES'].includes(country.country) &&
                (openCountries[country.country] ? (
                  <ExpandLess
                    onClick={() => handleCountryToggle(country.country)}
                    style={{ height: '14px' }}
                  />
                ) : (
                  <ExpandMore
                    onClick={() => handleCountryToggle(country.country)}
                    style={{ height: '14px' }}
                  />
                ))}
            </ListItem>

            {/* Liste des villes pour le pays, affichée si le pays est étendu */}
            <Collapse in={openCountries[country.country]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding style={{ paddingLeft: '2rem' }}>
                <Typography className={classes.subtitle}>
                  {intl.formatMessage({ id: 'new.Equipe_3' })}
                </Typography>
                {country.cities
                  .filter((city) => city.city !== 'all')
                  .map((city) => (
                    <ListItem
                      key={city.city}
                      button
                      onClick={() => handleCityClick({ name: city.city, agents: city.agents })}
                    >
                      <ListItemText
                        classes={{
                          primary: clsx(
                            classes.cityTitle,
                            selectedCity === city.city && classes.selectedCity,
                          ),
                        }}
                        primary={city.city}
                      />
                    </ListItem>
                  ))}
                <Typography className={classes.subtitle}>
                  {intl.formatMessage({ id: 'new.Equipe_3' })}
                </Typography>
                {country.regions.map((region) => (
                  <ListItem
                    key={region.region}
                    button
                    onClick={() => handleCityClick({ name: region.region, agents: region.agents })}
                  >
                    <ListItemText
                      classes={{
                        primary: clsx(
                          classes.cityTitle,
                          selectedCity === region.region && classes.selectedCity,
                        ),
                      }}
                      primary={region.region}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </div>
        ))}
      </List>
    </Drawer>
  )
}

export default MobileSidebar
