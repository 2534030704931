import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import clsx from 'clsx'
import { capitalize } from '../utils/helper'
import Link from '@material-ui/core/Link'
import { Image } from './Images'
import PhoneIcon from '@material-ui/icons/LocalPhone'

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(26),
    fontWeight: 600,
    lineHeight: 1.2,
    marginTop: theme.typography.pxToRem(18),
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: theme.typography.pxToRem(16),
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.typography.pxToRem(12),
      fontSize: theme.typography.pxToRem(30),
      fontWeight: 500,
    },
  },
  designation: {
    fontSize: '18px',
    fontFamily: `'Gotham Book', serif`,
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  phoneNumber: {
    fontSize: '12px',
    fontFamily: `'Gotham Light', serif`,
  },
  details: {
    textAlign: 'center',
  },
  container: {
    textAlign: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    '.gatsby-image-wrapper': {
      width: '150px',
    },
  },
  pb3: {
    paddingBottom: theme.spacing(3),
  },
  active: {
    borderBottom: '4px solid black',
  },
  num: {
    fontSize: '14px',
    fontFamily: `'Gotham Book', serif`,
  },
}))

const AvatarContainer = styled.div`
  cursor: pointer;
  .gatsby-image-wrapper {
    width: 150px;
  }
`

interface BannerProps {
  onClick?: () => void
  active?: boolean
  hideNumber?: boolean
  hideDesignation?: boolean
  agent: any
}

export const Avatar: React.FC<BannerProps> = (props) => {
  const intl = useIntl()
  const { onClick, active = false, hideNumber = false, hideDesignation = false, agent } = props
  const numLink = agent.phone ? 'tel:' + agent.phone?.split(' ').join('') : '#'
  const classes = useStyles()
  const currentAgent = agent.node

  return (
    <AvatarContainer
      onClick={() => onClick && onClick()}
      className={clsx(active && classes.active)}
    >
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          {currentAgent?.photo?.portraitSquare &&
          !currentAgent.photo.portraitSquare.includes('undefined') ? (
            <img
              src={currentAgent.photo.portraitSquare}
              alt="Profile"
              style={{
                borderRadius: '50%',
                width: '150px',
                height: '150px',
                overflow: 'hidden',
              }}
              loading="lazy"
            />
          ) : (
            <Image
              src="avatar_default.png"
              alt="Default Profile"
              layout="CONSTRAINED"
              className="slide-img"
              style={{
                borderRadius: '50%',
                width: '150px',
                height: '150px',
                overflow: 'hidden',
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.title}>{`${currentAgent?.fname} ${currentAgent?.lname}`}</Box>
        </Grid>
        <Grid item xs={12}>
          {hideDesignation ? null : (
            <Box className={classes.designation} style={{ fontFamily: 'Cormorant Garamond' }}>
              {currentAgent && currentAgent[`titre${capitalize(intl.locale)}`]}
            </Box>
          )}
        </Grid>
        {hideNumber ? null : (
          <Grid item xs={12} className={classes.phoneNumber}>
            <Link
              underline="none"
              href={numLink}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
              }}
            >
              <PhoneIcon style={{ height: '16px' }} />
              {currentAgent.phone}
            </Link>
          </Grid>
        )}
      </Grid>
    </AvatarContainer>
  )
}
