import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Grid, Typography } from '@material-ui/core'
import { Avatar } from '../../../Avatar'
import ContactAgentForm from '../../contact/contactAgentForm'
import ContactForm from '../../contact/contactForm'
import FormContactAgent from './FormContactAgent'
import { useIntl } from 'gatsby-plugin-intl'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: theme.spacing(2),
  },
  closeButton: {
    padding: theme.spacing(1),
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  desc: {
    paddingInline: '2rem',
    textAlign: 'center',
    fontFamily: `'Gotham Light', serif`,
    fontWeight: 325,
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      paddingInline: '0rem',
    },
  },
}))

interface AgentDialogProps {
  open: boolean
  onClose: () => void
  agent: any
}

const AgentDialog: React.FC<AgentDialogProps> = ({ open, onClose, agent }) => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <div className={classes.dialogTitle}>
        <DialogTitle></DialogTitle>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Grid container md={12}>
          <Grid item md={5}>
            <Avatar agent={agent} hideDesignation />
            <Typography className={classes.desc}>
              {(() => {
                switch (intl.locale) {
                  case 'en':
                    return agent.node.descriptionEn
                  case 'ar':
                    return agent.node.descriptionAr
                  case 'ru':
                    return agent.node.descriptionRu
                  case 'zh':
                    return agent.node.descriptionZh
                  case 'es':
                    return agent.node.descriptionEs
                  case 'fr':
                    return agent.node.descriptionFr
                  case 'pt':
                    return agent.node.descriptionPt
                  default:
                    return agent.node.descriptionFr
                }
              })()}
            </Typography>
          </Grid>
          <Grid item md={7}>
            <FormContactAgent backgroundColor={'#fff'} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default AgentDialog
