import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandMore'
import ExpandMore from '@material-ui/icons/ChevronRight'
import { useIntl } from 'gatsby-plugin-intl'
import { ListSubheader, Typography } from '@material-ui/core'
import myCountries from '../../../../data/countries.json'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 350,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 350,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  listItem: {
    padding: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },

  countryTitle: {
    fontFamily: `'Gotham Medium', serif`,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    textTransform: 'uppercase',
  },
  cityTitle: {
    fontFamily: `'Gotham Light',  serif`,
    fontWeight: 325,
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
  },
  selectedCity: {
    fontFamily: `'Gotham Medium',  serif`,
  },
  subtitle: {
    fontFamily: `'Gotham Medium',  serif`,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
    paddingInline: '1rem 0',
    paddingTop: '1.5rem',
  },
  filtreparPaysCTA: {
    fontFamily: `'Gotham Medium', serif`,
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: '#1d1d1b',
  },
}))

interface NodeAgent {
  id: string
  lname: string
  photo: any
  destination: string
  email: string
  phone: string

  descriptionEn: string
  descriptionEs: string
  descriptionFr: string
  descriptionPt: string

  titreEn: string
  titreEs: string
  titreFr: string
  titrePt: string
}
interface Agent {
  node: NodeAgent
}
interface City {
  city: string
  agents: Agent[]
}
interface Region {
  region: string
  agents: Agent[]
}
interface Country {
  country: string
  cities: City[]
  regions: Region[]
}
interface Location {
  name: string
  agents: Agent[]
}

interface TeamSidebarProps {
  countries: Country[]
  onCitySelect?: (location: Location) => void
  selectedCity: string
}

const TeamSidebar: React.FC<TeamSidebarProps> = ({ countries, onCitySelect, selectedCity }) => {
  const intl = useIntl()
  const classes = useStyles()
  const [openCountries, setOpenCountries] = useState<{ [key: string]: boolean }>({})

  const handleCountryToggle = (country: string) => {
    setOpenCountries((prev) => ({
      ...prev,
      [country]: !prev[country],
    }))
  }

  const handleCityClick = (location: Location) => {
    onCitySelect?.(location)
  }

  function getCountryfromLabel(code: string) {
    return myCountries.find((country) => country.country_code === code)
  }

  return (
    <List
      subheader={
        <ListSubheader component="div" className={classes.filtreparPaysCTA}>
          {intl.formatMessage({ id: 'new.Equipe_2' })}
        </ListSubheader>
      }
      style={{ paddingLeft: '0rem' }} // Espacement global de 1rem à gauche
    >
      <Divider variant="fullWidth" />
      {countries.map((country, index) => (
        <React.Fragment key={country.country}>
          <ListItem button>
            <ListItemText
              onClick={() => {
                handleCityClick({
                  name:
                    country.cities.find((city) => city.city === 'all')?.city ||
                    country.cities[0].city,
                  agents:
                    country.cities.find((city) => city.city === 'all')?.agents ||
                    country.cities[0].agents,
                })
              }}
              classes={{ primary: classes.countryTitle }}
              primary={intl.formatMessage({
                id: `destinations.${getCountryfromLabel(country?.country)?.label.toLowerCase()}`,
              })}
            />
            {['FR', 'ES'].includes(country.country) &&
              (openCountries[country.country] ? (
                <ExpandLess
                  onClick={() => handleCountryToggle(country.country)}
                  style={{ height: '14px' }}
                />
              ) : (
                <ExpandMore
                  onClick={() => handleCountryToggle(country.country)}
                  style={{ height: '14px' }}
                />
              ))}
          </ListItem>

          <Collapse in={openCountries[country.country]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ paddingLeft: '0rem' }}>
              <Typography className={classes.subtitle}>
                {intl.formatMessage({ id: 'new.Equipe_3' })}
              </Typography>
              {country.cities
                .filter((city) => city.city !== 'all')
                .map((city) => (
                  <ListItem
                    key={city.city}
                    button
                    onClick={() => handleCityClick({ name: city.city, agents: city.agents })}
                  >
                    <ListItemText
                      classes={{
                        primary: clsx(
                          classes.cityTitle,
                          selectedCity === city.city && classes.selectedCity,
                        ),
                      }}
                      primary={city.city}
                    />
                  </ListItem>
                ))}
              <Typography className={classes.subtitle}>
                {intl.formatMessage({ id: 'new.Equipe_4' })}
              </Typography>
              {country.regions.map((region) => (
                <ListItem
                  key={region.region}
                  button
                  onClick={() => handleCityClick({ name: region.region, agents: region.agents })}
                >
                  <ListItemText
                    classes={{
                      primary: clsx(
                        classes.cityTitle,
                        selectedCity === region.region && classes.selectedCity,
                      ),
                    }}
                    primary={region.region}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>

          {/* {index < countries.length - 1 && <Divider variant="fullWidth" />} */}
          {<Divider variant="fullWidth" />}
        </React.Fragment>
      ))}
    </List>
  )
}

export default TeamSidebar
